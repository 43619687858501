<template>
	<div>
		<common-table
				:itemScope="['lsell', 'productChildren']"
				:headers="headers"
				:listData="listData"
				@changePage="changePage"
				:loading="loading"
				:btnAction="true"
				@search="search"
				@remove="remove"
				@save="save"
				@edit="edit"
				ref="ct"
				:rightConfig="rightConfig"
				:leftConfig="leftConfig"
				:listDeploy="listDeploy"
				pageTitle="产品大类"
				:btnActionEvent="btnActionEvent"
				@otherTableOperate="otherTableOperate"
				@beforeOpenAddDialog="beforeOpenAddDialog"
				@otherRightOperate="otherRightOperate"
		>
			<template v-slot:lsell="{ item }">
				<w-switches
						inset
						label=""
						color="var(--v-primary-base)"
						@change="updateStatus(item.sqlid)"
						v-model="item.lsell" hide-details dense/>
			</template>

			<template v-slot:productChildren="{ item }">
				<v-btn depressed color="primary" @click="productChildren(item.code, item.type)">
					<v-icon class="iconfont mr-1" size="16">iconfont icon-guanli-fill</v-icon>
					产品小类
				</v-btn>
			</template>
		</common-table>

		<template v-if="qrObject.dialog">
			<qr-code
					:dialog.sync="qrObject.dialog"
					:param="qrObject.param"
					:subtitle="qrObject.subtitle"
					:pathNo="qrObject.pathNo"
			/>
		</template>

		<template v-if="combinationDialog">
			<combination
					:combination-dialog.sync="combinationDialog"
					:search-key="fixedParams['group']"
					@closeCombinationDialog="combinationDialog = false"/>
		</template>
	</div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/productManage/product-parent.js';
import editorText from "@/components/editorText/editorText.vue"
import qrCode from "@/components/qr-code/qr-code.vue"
import Combination from "./combination-qrcode-manage"

export default {
	mixins: [commonCurd],
	components: {
		qrCode,
		Combination
	},
	data() {
		return {
			api,
			combinationDialog: false,
			qrObject: {
				dialog: false,
				param: '',
				subtitle: ''
			},
			// 排序字段，不填默认sqlid
			sortname: 'seq',
			// 定义右侧按钮
			rightConfig: [
				{label: '组合项目', icon: 'erweima', event: 'openCombinationDialog'},
				{label: '新建', icon: 'zengjia', event: 'add'}
			],
			// 定义左侧搜索框
			leftConfig: [
				{type: 'input', label: '输入产品代码/名称', property: 'searchkey', width: '200px'}
			],
			btnActionEvent: [
				{
					event: 'editItem',
					icon: 'bianji2-fill',
					color: 'primary',
					tooltip: '编辑'
				}
				, {
					event: 'deleteItem',
					icon: 'shanchu-fill',
					color: 'var(--v-redness-base)',
					tooltip: '删除'
				},
				{
					event: 'phoneView',
					icon: 'erweima',
					color: 'primary',
					tooltip: '移动端'
				},
				// {
				//   event: 'pcView',
				//   icon: 'diannaoduan',
				//   color: 'primary',
				//   tooltip: '电脑端'
				// }
			],
			// 表格头部字段
			headers: [
				{text: '产品大类代码', value: 'code'},
				{text: '产品大类名称', value: 'description'},
				{text: '产品小类', value: 'productChildren'},
				{text: '排序号', value: 'seq'},
				{text: '预订状态', value: 'lsell'},
				{text: '操作', value: 'actions', width: 120}
			],
			// 表单字段
			listDeploy: {
				row: [
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'code',
						hasEditDisabledProperty: true,
						connector: {
							props: {
								required: true,
								label: "产品大类代码"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'description',
						connector: {
							props: {
								required: true,
								label: "产品大类名称"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'seq',
						connector: {
							props: {
								label: "排序号码",
								type: 'number'
							}
						}
					},
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'type',
						hasEditDisabledProperty: true,
						connector: {
							props: {
								required: true,
								label: "产品类型",
								items: []
							},
							on: {
								especially: (v) => {
									this.listDeploy.row[4].cols = v === '1' ? 6 : 0
									this.listDeploy.row[4].connector.style.display = v === '1' ? 'block' : 'none'
									this.listDeploy.row[4].connector.props.required = v === '1'
									if (v !== '1') {
										this.listDeploy.row[4].default = '-1'
									}
								}
							}
						}
					},
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'checkmode',
						connector: {
							props: {
								required: true,
								label: "核销模式",
								items: [
									{code: 0, desc: '过期自动核销'},
									{code: 1, desc: '门票发码核销'},
									{code: 2, desc: '发码核销'},
									{code: 3, desc: '短信核销'}
								]
							},
							style: {
								display: ''
							}
						}
					},
					{
						cols: 6,
						subassembly: "WSwitches",
						property: 'lsell',
						disabledDeleteDefault: true,
						connector: {
							props: {
								label: "预订状态",
								inset: true,
								dense: true
							}
						},
						default: true
					},
					// {
					// 	cols: 12,
					// 	subassembly: 'MaterialFile',
					// 	property: 'mlistpic',
					// 	connector: {
					// 		props: {
					// 			required: true,
					// 			showAddIcon: false,
					// 			hint: '大小不能超过2MB，格式仅限于jpg、png',
					// 			label: '上传图片',
					// 			title: '移动端列表图(686*320)',
					// 			fileType: 'commodity'
					// 		}
					// 	}
					// },
					{
						cols: 12,
						subassembly: 'MaterialFile',
						property: 'mslidepics',
						connector: {
							props: {
								required: true,
								subTitle: '点击添加按钮新增上传区域，拖动选中的文件区域可对其排序，' +
										'图片建议：宽高比例4:3，大小不能超过2MB，格式仅限于jpg、png、jpeg、webp，数量上限为6张',
								label: '上传图片',
								title: '应用端轮播图',
								fileType: 'commodity'
							}
						}
					},
					// {
					// 	cols: 12,
					// 	subassembly: 'MaterialFile',
					// 	property: 'listpic',
					// 	connector: {
					// 		props: {
					// 			required: false,
					// 			showAddIcon: false,
					// 			hint: '大小不能超过2MB，格式仅限于jpg、png',
					// 			label: '上传图片',
					// 			title: '电脑端列表图(1264*713)',
					// 			fileType: 'commodity'
					// 		}
					// 	}
					// },
					// {
					// 	cols: 12,
					// 	subassembly: 'MaterialFile',
					// 	property: 'slidepics',
					// 	connector: {
					// 		props: {
					// 			required: false,
					// 			hint: '大小不能超过2MB，格式仅限于jpg、png',
					// 			label: '上传图片',
					// 			title: '电脑端轮播图(680*340)',
					// 			fileType: 'commodity'
					// 		}
					// 	}
					// },
					{
						cols: 12,
						subassembly: 'div',
						connector: {
							class: 'form-row-title',
							domProps: {
								innerHTML: '产品简介'
							}
						}
					},
					{
						cols: 12,
						subassembly: 'VTextarea',
						property: 'richtext',
						connector: {
							props: {
								solo: true,
								rows: 2,
								outlined: true,
								flat: true
							}
						}
					},
					{
						cols: 12,
						subassembly: 'div',
						connector: {
							class: 'form-row-title',
							domProps: {
								innerHTML: '产品介绍'
							}
						}
					},
					{
						cols: 12,
						subassembly: editorText,
						property: 'detailtext',
						connector: {
							props: {
								multi: 2
							}
						}
					},
					{
						cols: 12,
						subassembly: 'div',
						connector: {
							class: 'form-row-title',
							domProps: {
								innerHTML: '购买须知'
							}
						}
					},
					{
						cols: 12,
						subassembly: editorText,
						property: 'noticetext',
						connector: {
							props: {
								multi: 2
							}
						}
					}
				]
			}
		}
	},
	methods: {
		productChildren(code, type) {
			this.$router.push({name: 'productChildren', query: {code: code, type: type, group: this.fixedParams.group}});
		},
		beforeOpenAddDialog() {
			this.getCommonSelect();
		},
		otherTableOperate(event, item) {
			if (event === 'phoneView') {
				this.qrObject = {
					dialog: true,
					param: item.code,
					subtitle: item.description,
					pathNo: 10
				}
			}
		},
		beforeSave(form) {
			form.groupid = this.fixedParams.group
		},
		// 通用下拉框数据
		getCommonSelect() {
			this.axios.post(
					this.select_data, {keyname: "spustype"}
			).then((res) => {
						if (res?.data) {
							this.listDeploy.row[3].connector.props.items = res.data[0].values
						}
					}
			);
		},
		async createdExtend() {
			let res = await this.axios.post(this.select_data, {keyname: 'warestype'});
			if (res.data[0]?.values[0]?.code) {
				this.fixedParams['group'] = res.data[0].values[0].code
			}
		},
		otherRightOperate(event) {
			if (event === 'openCombinationDialog')
				this.combinationDialog = true
		}
	},
	mounted() {
		this.getCommonSelect()
	},
	watch: {
		'$store.state.oneSource': {
			handler(v) {
				if (v) {
					this.fixedParams['group'] = v
					this.list()
				}
			}
		}
	}
}
</script>

<style scoped lang="scss">

</style>
