<template>
  <div>
    <exhibition-data-box :dialog.sync="dialog" title="组合项目管理" :contentHeight="fixedHeight + 90" :width="874">
      <common-table
          :key="new Date().getTime()"
          :headers="headers"
          :listData="listData"
          :loading="loading"
          :dialogTable=true
          ref="ct"
          pageTitle="项目组合"
          :fixedHeight="fixedHeight"
          :rightConfig="rightConfig"
          :normalPage="false"
          :btnAction="true"
          @remove="remove"
          :btnActionEvent="btnActionEvent"
          @otherTableOperate="otherTableOperate"
          @otherRightOperate="otherRightOperate"
      />
    </exhibition-data-box>
    <template v-if="addDialog">
      <form-menu
          :center="true"
          :center_btn="true"
          :listDeploy="listDeploy"
          height="320px"
          width="520px"
          :ridDivision="true"
          :dialog.sync="addDialog"
          rightbtn_text="确定"
          leftbtn_text="取消"
          @resetForm="addDialog = false"
          @validateSave="validateSave"
          ref="formRef"
          title="新建组合"/>
    </template>
    <template v-if="qrObject.dialog">
      <qr-code
          :dialog.sync="qrObject.dialog"
          :param="qrObject.param"
          :subtitle="qrObject.subtitle"
          :pathNo="qrObject.pathNo"
      />
    </template>
  </div>
</template>

<script>
import ExhibitionDataBox from "@/components/exhibition-data-box/exhibition-data-box"
import commonCurd from "@/mixin/commonCurd"
import api from '@/api/productManage/scenic-spot-product-combination-manage'
import formMenu from "@/components/form-menu/form-menu.vue"
import qrCode from "@/components/qr-code/qr-code.vue"

export default {
  components: {
    ExhibitionDataBox,
    formMenu,
    qrCode
  },
  mixins: [commonCurd],
  props: {
    combinationDialog: Boolean,
    searchKey: String
  },
  data() {
    return {
      api,
      qrObject: {
        dialog: false,
        param: '',
        subtitle: ''
      },
      dialog: false,
      fixedHeight: 0,
      headers: [
        {text: '组合代码', value: 'code'},
        {text: '组合名称', value: 'description'},
        {text: '关联项目', value: 'groups', width: ''},
        {text: '操作', value: 'actions', width: 120}
      ],
      rightConfig: [
        {label: '新建', icon: 'zengjia', event: 'add1'}
      ],
      btnActionEvent: [
        {
          event: 'editItem1',
          icon: 'bianji2-fill',
          color: 'primary',
          tooltip: '编辑'
        },
        {
          event: 'phoneView',
          icon: 'erweima',
          color: 'primary',
          tooltip: '移动端'
        }, {
          event: 'deleteItem',
          icon: 'shanchu-fill',
          color: 'var(--v-redness-base)',
          tooltip: '删除'
        }
      ],
      addDialog: false,
      listDeploy: {
        row: [
          {
            cols: 6,
            subassembly: "VTextField",
            property: 'code',
            hasEditDisabledProperty: true,
            connector: {
              props: {
                required: true,
                label: "组合代码"
              }
            }
          },
          {
            cols: 6,
            subassembly: "VTextField",
            property: 'description',
            connector: {
              props: {
                required: true,
                label: "组合名称"
              }
            }
          },
          {
            cols: 6,
            subassembly: "WAutocomplete",
            property: 'groups',
            connector: {
              props: {
                required: false,
                label: "关联产品",
                hint: '不选即默认关联全部产品',
                persistentHint: true,
                items: [],
                multiple: true,
                chips: true,
                arrayType: false
              }
            }
          }
        ]
      }
    }
  },
  watch: {
    combinationDialog: {
      handler(v) {
        if (v) {
          this.dialog = true;
        }
      },
      immediate: true
    },
    dialog: {
      handler(v) {
        if (!v) {
          this.$emit("closeCombinationDialog");
        }
      }
    },
    addDialog: {
      handler(v) {
        if (v) {
          this.axios.post(this.select_data, {
            keyname: 'spugroup',
            params: {key: 'spugroup', value: this.searchKey}
          }).then((res) => {
                if (res?.data) {
                  this.listDeploy.row[2].connector.props.items = res.data[0].values;
                }
              }
          )
        }
      }
    }
  },
  mounted() {
    this.tableContentFieldWidth();
    this.fixedHeight = document.documentElement.clientHeight * 0.6;
  },
  methods: {
    // 监听内容宽度变化
    tableContentFieldWidth() {
      this.headers[2].width = (this.$refs.ct.$el.clientWidth * 0.5) + 'px';
    },
    otherRightOperate(event) {
      if (event === 'add1') {
        this.listDeploy.sqlid = 0
        for (const obj of this.listDeploy.row) {
          delete obj.default
          if (obj.connector?.props) {
            obj.connector.props.disabled = false
            delete obj.connector.props.value
          }
        }
        this.addDialog = true
      }
    },
    validateSave(item, callback) {
      if (item.done) {
        item.form.sqlid = this.listDeploy.sqlid;
        item.form.groupid = this.searchKey
        this.axios.post(this.api.save, item.form).then(r => {
          if (r.code === this.staticVal.Code.Success) {
            this.snackbar.success("保存成功");
            callback();
            this.list();
          } else {
            this.$refs.formRef.load = false;
          }
        });
      }
    },
    async otherTableOperate(event, object) {
      if (event === this.btnActionEvent[1].event) {
        this.qrObject = {
          dialog: true,
          param: object.code,
          subtitle: object.description,
          pathNo: 9
        }
      } else if (event === this.btnActionEvent[0].event) {
        const sqlid = object.sqlid
        const item = await this.getObject(sqlid)
        this.listDeploy.sqlid = sqlid;
        for (const obj of this.listDeploy.row) {
          obj.default = item[obj.property]
          if (obj.hasEditDisabledProperty) {
            obj.connector.props.disabled = true;
          }
        }
        this.addDialog = true
      }
    },
    createdExtend() {
      this.form.searchkey = this.searchKey
    }
  }
}
</script>

<style scoped>

</style>
